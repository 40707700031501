/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BadesulLoanData from './components/BadesulLoanData';
import DefaultLoanData from './components/DefaultLoanData';

const LoanData = ({ loan, ...rest }) => {
  const isBadesul = loan?.portfolioName?.toLowerCase().includes('badesul');

  const handleLoanData = () => {
    if (loan && isBadesul) {
      return (
        <BadesulLoanData
          loan={loan}
          {...rest}
        />
      );
    }

    if (loan && loan.portfolioName) {
      return (
        <DefaultLoanData
          loan={loan}
          {...rest}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (process.env.ENVIRONMENT !== 'development') {
      return;
    }

    if (loan && !loan.portfolioName) {
      console.log('Loan sem portfolioName chegou no loan data');
    }
  }, [loan]);

  return (
    <>
      {handleLoanData()}
    </>
  );
};

LoanData.propTypes = {
  onClose: PropTypes.func,
  onCompleted: PropTypes.func,
  refetch: PropTypes.func,
  visible: PropTypes.bool,
  loan: PropTypes.shape({
    totalAmount: PropTypes.number,
    signatures: PropTypes.array,
    additive: PropTypes.shape({
      url: PropTypes.string,
    }),
    status: PropTypes.shape(),
    portfolioName: PropTypes.string,
    bankerId: PropTypes.string,
    ccb: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

export default LoanData;
