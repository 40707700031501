// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1000px) {
  #request-screen .request-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#request-screen .stone-success-icon {
  font-size: 3rem;
  color: #00A868;
  margin-bottom: 1rem;
}
#request-screen .subtitle .revision-fields {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
#request-screen .subtitle .revision-fields li {
  text-align: center;
}
#request-screen .subtitle .revision-fields li b {
  font-weight: 600;
}
#request-screen .success-content-container {
  padding-bottom: 2rem;
}
#request-screen .loan-infos-cards {
  display: flex;
}
#request-screen .loan-infos-cards .loan-infos-card + .loan-infos-card {
  margin-left: 1.6rem;
}
@media (max-width: 768px) {
  #request-screen .loan-infos-cards {
    flex-direction: column;
  }
  #request-screen .loan-infos-cards .loan-infos-card + .loan-infos-card {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  #request-screen {
    height: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/RequestBadesulPronampeScreen/styles.less"],"names":[],"mappings":"AACE;EAAA;IAEI,aAAA;IACA,mBAAA;IACA,uBAAA;EAAJ;AACF;AANA;EAUI,eAAA;EACA,cAAA;EACA,mBAAA;AADJ;AAXA;EAiBM,oBAAA;EACA,sBAAA;EACA,mBAAA;AAHN;AAhBA;EAqBQ,kBAAA;AAFR;AAnBA;EAuBU,gBAAA;AADV;AAtBA;EA8BI,oBAAA;AALJ;AAzBA;EAkCI,aAAA;AANJ;AA5BA;EAoCM,mBAAA;AALN;AAQI;EAAA;IACE,sBAAA;EALJ;EAIE;IAGI,iBAAA;IACA,gBAAA;EAJN;AACF;AAQE;EAAA;IACE,YAAA;EALF;AACF","sourcesContent":["#request-screen {\n  @media(min-width: 1000px) {\n    .request-content {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n\n  .stone-success-icon {\n    font-size: 3rem;\n    color: #00A868;\n    margin-bottom: 1rem;\n  }\n\n  .subtitle {\n    .revision-fields {\n      display: inline-flex;\n      flex-direction: column;      \n      align-items: center;\n      li {\n        text-align: center;\n        b {\n          font-weight: 600;\n        }\n      }\n    }\n  }\n\n  .success-content-container {\n    padding-bottom: 2rem;\n  }\n\n  .loan-infos-cards {\n    display: flex;\n    .loan-infos-card + .loan-infos-card {\n      margin-left: 1.6rem;\n    }\n\n    @media(max-width: @md-screen) {\n      flex-direction: column;\n      .loan-infos-card + .loan-infos-card {\n        margin-left: 0rem;\n        margin-top: 1rem;\n      }\n    }\n  }\n\n  @media(max-width: 768px) {\n    height: auto;\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
