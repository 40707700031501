/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  UploadCard,
} from 'components';

const { Item } = Form;

const businessFile = {
  '204-6': 'Estatuto social',
  '205-6': 'Estatuto social',
  '206-2': 'Contrato social',
  '213-5': 'Certificado da condição do MEI ou Registro de requisição EI ou Declaração de incorporação da EIRELLI',
  '223-2': 'Contrato social',
  '224-0': 'Contrato social',
  '230-5': 'Declaração de incorporação EIRELI',
  '231-5': 'Declaração de incorporação EIRELI',
  '401-4': 'Requisição de registro EI',
};

const BadesulPronampeBusinessFileStep = ({
  files: {
    proofOfAddress,
    bylaws,
    revenueStatement,
    majorityPartnerSelfie,
  } = {},
  additionalFiles: {
    raisPerson,
    taxReceiptOfBilling,
    annexV,
    eSocialPrint,
  } = {},
  form,
  type,
  address,
  hideNotAnalyzedFiles,
  businessDocumentsRevised,
  setBusinessDocumentsRevised,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      bylawsFileId: bylaws?.id,
      proofOfAddressFileId: proofOfAddress?.id,
      majorityPartnerSelfieFileId: majorityPartnerSelfie?.id,
      badesulTaxReceiptOfBillingFileId: taxReceiptOfBilling?.id,
      badesulAnnexVFileId: annexV?.id,
      badesulESocialPrintFileId: eSocialPrint?.id,
    });
  }, [proofOfAddress, bylaws, revenueStatement, taxReceiptOfBilling, annexV, eSocialPrint]);

  return (
    <>
      <Form
        className="form-footer"
        form={form}
        layout="vertical"
      >
        <Row gutter={[0, 20]}>
          {(bylaws?.analysis?.approved === false || !hideNotAnalyzedFiles) && (
            <Col span={24} className="bylaws-upload">
              <Item name="bylawsFileId" rules={[{ required: true, message: 'Por favor, insira o documento contratual' }]}>
                <UploadCard
                  id="bylaws-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={bylaws}
                  alert={(businessFile[type] === 'Contrato social' || businessFile[type] === 'Estatuto social')
                    && 'O documento precisa estar completo, com assinaturas e carimbos da junta comercial.'}
                  label={`${businessFile[type] || 'Documento contratual'} da empresa`}
                  actionButtonText={bylaws?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={bylaws?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ bylawsFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {(!hideNotAnalyzedFiles || address?.analysis?.approved === false) && (
            <Col span={24} className="proofOfAddressFileId">
              <Item name="proofOfAddressFileId" rules={[{ required: true, message: 'Por favor, insira o comprovante de endereço' }]}>
                <UploadCard
                  id="proof-of-address-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={proofOfAddress}
                  alert="Escolha uma das opções: Conta de luz, água, gás, telefone ou internet, com no máximo 90 dias, ou contrato de locação do imóvel. Enviar conta aberta. Não aceitamos somente a capa."
                  label="Comprovante de endereço"
                  actionButtonText={address?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={address?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ proofOfAddressFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {(!hideNotAnalyzedFiles || raisPerson?.analysis?.approved === false) && (
            <Col span={24} className="raisPersonFileId">
              <Item name="raisPersonFileId" rules={[{ required: true, message: 'Por favor, insira o documento' }]}>
                <UploadCard
                  id="rais-document-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={raisPerson}
                  alert="Comprovação de que a empresa está em dia com a entrega da Relação Anual de Informações Sociais - RAIS"
                  label="RAIS e-social"
                  actionButtonText={raisPerson?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={raisPerson?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ raisPersonFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          <Col span={24}>
            <Item
              name="badesulTaxReceiptOfBillingFileId"
              rules={[
                {
                  required: true,
                  message: 'Insira a foto do documento solicitado',
                },
              ]}
            >
              <UploadCard
                id="badesul-tax-receipt-of-billing-file-id"
                accept="image/png,image/jpeg,application/pdf"
                label="Comprovante fiscal de faturamento"
                actualFile={taxReceiptOfBilling}
                onCompleted={async ({ id }) => {
                  await form.setFieldsValue({ badesulTaxReceiptOfBillingFileId: id });
                }}
              />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="badesulAnnexVFileId">
              <UploadCard
                id="badesul-annex-v-file-id"
                accept="image/png,image/jpeg,application/pdf"
                label="Anexo V assinado digitalmente (ICP-Brasil ou via GOV.BR)"
                alert="Caso seja optante do E-social"
                validateSignature
                actualFile={annexV}
                onCompleted={async ({ id }) => {
                  await form.setFieldsValue({ badesulAnnexVFileId: id });
                }}
              />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="badesulESocialPrintFileId">
              <UploadCard
                id="badesul-e-social-print-file-id"
                accept="image/png,image/jpeg,application/pdf"
                label="Captura de tela do cadastro do E-social"
                alert="Caso seja optante do E-social"
                actualFile={eSocialPrint}
                onCompleted={async ({ id }) => {
                  await form.setFieldsValue({ badesulESocialPrintFileId: id });
                }}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BadesulPronampeBusinessFileStep;
