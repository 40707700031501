// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyra-drawer-content {
  display: flex;
  flex-direction: column;
}
.gyra-drawer-content .drawer-body {
  flex-grow: 1;
}
.gyra-drawer-content .drawer-body .ant-carousel,
.gyra-drawer-content .drawer-body .slick-slider,
.gyra-drawer-content .drawer-body .slick-list,
.gyra-drawer-content .drawer-body .slick-track,
.gyra-drawer-content .drawer-body .slick-slide,
.gyra-drawer-content .drawer-body .slick-slide > div,
.gyra-drawer-content .drawer-body .carousel-content,
.gyra-drawer-content .drawer-body .badesul-signature-process,
.gyra-drawer-content .drawer-body iframe {
  height: 100% !important;
}
.gyra-drawer-content .badesul-signature-process {
  display: flex;
  flex-direction: column;
}
.carousel-content {
  height: 100%;
}
.steps-content {
  height: 100%;
}
.badesul-signature-options-step {
  margin-top: 2rem;
  display: flex;
}
.badesul-signature-options-step .gyramais-btn + .gyramais-btn {
  margin-left: 0.5rem;
}
.badesul-digital-signature-step {
  margin-top: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/others/SignatureProcess/components/BadesulSignatureProcess/styles.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AAHA;EAIQ,YAAA;AAER;AANA;;;;;;;;;EAcY,uBAAA;AAGZ;AAjBA;EAmBQ,aAAA;EACA,sBAAA;AACR;AAGA;EACI,YAAA;AADJ;AAGA;EACI,YAAA;AADJ;AAIA;EACI,gBAAA;EACA,aAAA;AAFJ;AAAA;EAKQ,mBAAA;AAFR;AAMA;EACI,gBAAA;AAJJ","sourcesContent":[".gyra-drawer-content {\n    display: flex;\n    flex-direction: column;\n    .drawer-body{\n        flex-grow: 1;\n        .ant-carousel,\n        .slick-slider, \n        .slick-list, \n        .slick-track,\n        .slick-slide,\n        .slick-slide > div,\n        .carousel-content,\n        .badesul-signature-process,\n        iframe {\n            height: 100% !important;\n        }\n    }\n\n    .badesul-signature-process{\n        display: flex;\n        flex-direction: column;\n        \n    }\n}\n.carousel-content{\n    height: 100%;\n}\n.steps-content{\n    height: 100%;\n}\n\n.badesul-signature-options-step {\n    margin-top: 2rem;\n    display: flex;\n    \n    .gyramais-btn + .gyramais-btn {\n        margin-left: .5rem;\n    }\n}\n\n.badesul-digital-signature-step {\n    margin-top: 2rem;\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
