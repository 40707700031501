/* eslint-disable indent */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
 badescPortfolios, badesulPortfolios,
} from 'utils';
import DefaultLoanCard from './components/DefaultLoanCard';
import BadescLoanCard from './components/BadescLoanCard';
import BadesulLoanCard from './components/BadesulLoanCard';

import './styles.less';

const LoanCard = ({
  loan,
  ...rest
}) => {

  const getContext = () => {
    if (badesulPortfolios?.includes(loan?.portfolioName)) {
      return (
        <BadesulLoanCard
          loan={loan}
          {...rest}
        />
      );
    }
    if (badescPortfolios?.includes(loan?.portfolioName)) {
      return (
        <BadescLoanCard
          loan={loan}
          {...rest}
        />
      );
    }
    if (loan?.portfolioName) {
      return (
        <DefaultLoanCard
          loan={loan}
          {...rest}
        />
      );
    }

    return null;
  };

  return (
    <>
      {getContext()}
    </>
  );
};

LoanCard.propTypes = {
  loan: PropTypes.shape(),
  detailed: PropTypes.bool,
  index: PropTypes.number,
  refetch: PropTypes.func,
  onClickSign: PropTypes.func,
  flow: PropTypes.string,
};

export default LoanCard;
