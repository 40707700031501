/* eslint-disable no-nested-ternary */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Steps,
} from 'antd';
import {
  Modal,
} from 'components/sdk';
import './styles.less';

const { Step } = Steps;

const TutorialModal = ({
  steps,
  visible,
  title,
  onClose,
}) => {
  const [current, setCurrent] = useState(0);

  const handleClose = () => {
    setCurrent(0);
    onClose();
  };

  return (
    <Modal
      style={{ whiteSpace: 'pre-line' }}
      title={title}
      centered
      visible={Boolean(steps) && visible}
      closable={false}
      onCancel={handleClose}
      id="tutorial-modal"
      okButton={{
        text: steps?.[current]?.nextButtonText
          ? steps[current].nextButtonText
          : (current === steps?.length - 1 ? 'Concluir' : 'Prosseguir'),
        onClick: async () => {
          const resolve = current === steps?.length - 1
            ? handleClose
            : () => setCurrent(current + 1);

          if (steps?.[current]?.nextAction) {
            await steps?.[current]?.nextAction?.(resolve);
            return;
          }

          resolve();
        },
        loading: steps?.[current]?.nextButtonLoading,
        disabled: steps?.[current]?.nextButtonDisabled,
      }}
      backButton={current === 0 ? {
        text: 'Fechar',
        onClick: handleClose,
        color: 'secondary',
      } : {
        text: 'Voltar',
        onClick: () => setCurrent(current - 1),
        color: 'secondary',
      }}
    >
      {steps && (
        <>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="tutorial-modal-steps-content">{steps[current].content}</div>
        </>
      )}
    </Modal>
  );
};

TutorialModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.shape),
};

export default memo(TutorialModal);
