/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BadesulSignatureDrawer from './components/BadesulSignatureDrawer';
import DefaultSignatureDrawer from './components/DefaultSignatureDrawer';

const SignatureDrawer = ({ loan, ...rest }) => {
  const isBadesul = loan?.portfolioName?.toLowerCase().includes('badesul');

  const handleSignatureDrawer = () => {
    if (loan && isBadesul) {
      return (
        <BadesulSignatureDrawer
          loan={loan}
          {...rest}
        />
      );
    }

    if (loan && loan.portfolioName) {
      return (
        <DefaultSignatureDrawer
          loan={loan}
          {...rest}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (process.env.ENVIRONMENT !== 'development') {
      return;
    }

    if (loan && !loan.portfolioName) {
      console.log('Loan sem portfolioName chegou no fluxo de assinatura');
    }
  }, [loan]);

  return (
    <>
      {handleSignatureDrawer()}
    </>
  );
};

SignatureDrawer.propTypes = {
  onClose: PropTypes.func,
  onCompleted: PropTypes.func,
  refetch: PropTypes.func,
  visible: PropTypes.bool,
  loan: PropTypes.shape({
    totalAmount: PropTypes.number,
    signatures: PropTypes.array,
    additive: PropTypes.shape({
      url: PropTypes.string,
    }),
    status: PropTypes.shape(),
    portfolioName: PropTypes.string,
    bankerId: PropTypes.string,
    ccb: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

export default SignatureDrawer;
