import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import {
  AuthenticationScreen,
  BusinessCreditProposalScreen,
  BusinessRegisterScreen,
  BusinessUserManagementScreen,
  DirectDataProblemScreen,
  EnablePreApprovedScreen,
  FirstIntegrationScreen,
  HomeScreen,
  IntegrationScreen,
  LoansScreen,
  LogInScreen,
  NotFoundScreen,
  OldPasswordScreen,
  PartnerLandingScreen,
  PreApprovedScreen,
  PromoRenegotiationScreen,
  RequestScreen,
  UserScreen,
  BusinessBadescRegisterScreen,
  RequestBadescScreen,
} from 'screens';
import {
  useQuery, useSubscription, useMutation,
} from '@apollo/client';
import { LOAN_UPDATED } from 'services/graphql/subscriptions';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import queryString from 'query-string';
import { handleLogin } from 'utils/hooks/handleLogin';
import { useValidateLoggedInUser } from 'utils/hooks/useValidateLoggedInUser';
import { usePromoRenegotiationFlow } from 'utils/hooks/usePromoRenegotiationFlow';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { clarity } from 'react-microsoft-clarity';
import PrePaymentScreen from 'screens/PrePaymentScreen';
import { Spin } from 'antd';
import RequestBadesulMicrocreditoScreen from 'screens/RequestBadesulMicrocreditoScreen';
import RequestBadesulPronampeScreen from 'screens/RequestBadesulPronampeScreen';
import { APPLY_COUPON, ME } from './graphql';
import { meVar } from '../services/graphql/cache';
import PreIntegrationScreen from '../screens/PreIntegrationScreen/pre-integration-screen';
import { badescPortfolios } from '../utils';
import BusinessBadesulMicrocreditoRegisterScreen from '../screens/BusinessBadesulMicrocreditoRegisterScreen';
import BusinessBadesulPronampeRegisterScreen from '../screens/BusinessBadesulPronampeRegisterScreen';

if (process.env.ENVIRONMENT === 'production') {
  ReactGA.initialize('UA-144865542-1');
  ReactPixel.init('2021246001421111');
  TagManager.initialize({
    gtmId: 'GTM-MFXZSFG',
  });
  hotjar.initialize(1079658, 6);
}

const trackPage = (page) => {
  if (process.env.ENVIRONMENT === 'production') {
    ReactGA.set({
      page,
    });
    ReactGA.pageview(page);
    ReactPixel.pageView();
  }
};

const getCurrentRequestScreenByPortfolio = ((context) => {
  if (badescPortfolios.includes(context)) {
    return RequestBadescScreen;
  }
  if (context === 'Badesul Microcrédito') {
    return RequestBadesulMicrocreditoScreen;
  }
  if (context === 'Badesul Pronampe') {
    return RequestBadesulPronampeScreen;
  }

  return RequestScreen;
});

const getCurrentRegisterScreenByPortfolio = ((context) => {
  if (badescPortfolios.includes(context)) {
    return BusinessBadescRegisterScreen;
  }
  if (context === 'Badesul Microcrédito') {
    return BusinessBadesulMicrocreditoRegisterScreen;
  }
  if (context === 'Badesul Pronampe') {
    return BusinessBadesulPronampeRegisterScreen;
  }

  return BusinessRegisterScreen;
});

const getRoutes = (currentContext) => {

  const routes = [
    {
      path: '/',
      component: HomeScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/autenticacao',
      component: AuthenticationScreen,
      exact: true,
    },
    {
      path: '/cadastro/senha',
      component: OldPasswordScreen,
      exact: true,
    },
    {
      path: '/cadastro/pendencias',
      component: DirectDataProblemScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/cadastro/integracoes',
      component: FirstIntegrationScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/cadastro/integracoes/:value',
      component: IntegrationScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/cadastro/status',
      component: getCurrentRequestScreenByPortfolio(currentContext?.loan?.portfolioName),
      exact: true,
      redirect: '/login',
    },
    {
      path: '/propostas',
      component: BusinessCreditProposalScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/cadastro/empresa',
      component: getCurrentRegisterScreenByPortfolio(currentContext?.loan?.portfolioName),
      exact: true,
      redirect: '/login',
    },
    {
      path: '/cadastro/pre-aprovado',
      component: PreApprovedScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/cadastro/pre-aprovado/liberar',
      component: EnablePreApprovedScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/plataformas-cadastradas',
      component: PreIntegrationScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/login',
      component: LogInScreen,
    },
    {
      path: '/emprestimos',
      component: LoansScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/perfil',
      component: UserScreen,
      exact: true,
      redirect: '/login',
    }, {
      path: '/antecipacao',
      component: PrePaymentScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/parceiros',
      component: PartnerLandingScreen,
      exact: true,
    },
    {
      path: '/olist',
      component: PartnerLandingScreen,
      exact: true,
    },
    {
      path: '/promo-renegociacao',
      component: PromoRenegotiationScreen,
      exact: true,
      redirect: '/login',
    }, {
      path: '/gerenciar-responsaveis',
      component: BusinessUserManagementScreen,
      exact: true,
      redirect: '/login',
    },
    {
      path: '/cadastro/revisao',
      component: BusinessRegisterScreen,
      exact: true,
      redirect: '/login',
    },
  ];

  return routes;
};

const PrivateRoute = ({ component: Component, me, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!me
      ? (<div><Redirect to={rest.redirect} /></div>)
      : (<Component {...props} />))}
  />
);

PrivateRoute.propTypes = {
  me: PropTypes.shape({}),
  component: PropTypes.elementType,
};

const Routes = () => {
  const history = useHistory();
  const { pathname, search, state } = useLocation();
  const { checkActions, actionsLoading } = usePartnerActionsContext();
  const { tryToChangeBusinessAndRedirect } = handleLogin();
  const {
    currentContext,
    contextLoading,
    themeAlreadyApplied,
  } = useBusinessLoanContext();

  const loggedInNow = state?.params?.loggedInNow;

  const { promo } = queryString.parse(search);

  useValidateLoggedInUser();
  usePromoRenegotiationFlow();

  const routes = getRoutes(currentContext);

  const isOnAuthenticatedScreen = () => (
    routes
      .filter((route) => route.redirect && route.path === pathname)
      .length > 0
  );

  const urlPaths = pathname.split('/');

  // Indagar o porque essa query me está sempre sendo executado, parece um erro no skip
  const { data: { me } = {}, refetch } = useQuery(ME, {
    skip: (!meVar() || !isOnAuthenticatedScreen()),
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ me } = {}) => {
      if (!me?.id) {
        return;
      }

      const {
        email,
      } = me;
      Sentry.setUser({ email });

    },
  });

  useEffect(() => {
    if (contextLoading || !currentContext) {
      return;
    }

    const isProfileScreen = urlPaths.includes('perfil');
    const isPrepaymentScreen = urlPaths.includes('antecipacao');
    const isCreditProposalScreen = urlPaths.includes('propostas');

    if (isProfileScreen || isPrepaymentScreen || isCreditProposalScreen) {
      return;
    }

    const isOnlyScore = checkActions(['only-score']);

    const { stone } = meVar();

    if (!stone && !isOnlyScore) {
      return false;
    }

    const statusValue = currentContext?.loan?.status?.value;

    if (
      ['integrating', 'new'].includes(statusValue)
      && pathname !== '/cadastro/integracoes'
    ) {
      history.push('/cadastro/integracoes');
      return true;
    }

    if (['integrated', 'verifying', 'score', 'completed'].includes(statusValue)
      && pathname !== '/cadastro/status'
      && pathname !== '/plataformas-cadastradas'
    ) {
      history.push('/cadastro/status');
      return true;
    }

  }, [pathname, actionsLoading, currentContext, contextLoading]);

  useEffect(() => {
    if (process.env.ENVIRONMENT !== 'production') {
      return;
    }

    if (me) {
      clarity.init('dcs3mb6mxq');
      clarity.identify(me.id);
    }
  }, [me]);

  useSubscription(LOAN_UPDATED, {
    variables: { ids: me?.currentBusiness?.loans?.map(({ id }) => id) },
    skip: !me?.currentBusiness?.loans?.[0],
    onSubscriptionData: () => refetch(),
  });

  const [applyCoupon] = useMutation(APPLY_COUPON, {
    refetchQueries: [{
      query: ME,
    }],
    onCompleted: () => {
      localStorage.removeItem('coupon');
      history.replace(`${window.location.pathname}`);
      window.location.reload();
    },
  });

  useEffect(() => {
    if (meVar()) refetch();
    trackPage(pathname);
  }, [pathname]);

  useEffect(() => {
    if (loggedInNow) {
      tryToChangeBusinessAndRedirect();
    }
  }, [loggedInNow]);

  const handlingPromo = async () => {
    if (promo) {
      if (!isOnAuthenticatedScreen()) {
        localStorage.setItem('coupon', JSON.stringify({ coupon: promo }));
      } else {
        const loanId = me?.currentBusiness?.loans[0]?.id;
        if (me) {
          await applyCoupon({
            variables: {
              loanId,
              promo,
            },
          });
        }
      }
    } else {
      const savedCoupon = localStorage.getItem('coupon') !== undefined ? JSON.parse(localStorage.getItem('coupon')) : null;
      if (savedCoupon) {
        await applyCoupon({
          variables: {
            loanId: me?.currentBusiness?.loans[0]?.id,
            promo: savedCoupon.coupon,
          },
        });
      }
    }
  };

  useEffect(() => {
    handlingPromo();
  }, [me, promo]);

  if (me && !themeAlreadyApplied) {
    return <Spin />;
  }

  return (
    <Switch>
      {routes.filter(({ redirect }) => !redirect).map(({
        path,
        exact,
        component,
      }) => (
        <Route
          key={path}
          exact={exact}
          path={path}
          component={component}
        />
      ))}
      {routes.filter(({ redirect }) => redirect).map(({
        path,
        exact,
        redirect,
        component,
      }) => (
        <PrivateRoute
          me={meVar()}
          key={path}
          exact={exact}
          path={path}
          redirect={redirect}
          component={component}
        />
      ))}
      <Route path="*" component={NotFoundScreen} />
    </Switch>
  );
};

export default Routes;
