/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Carousel,
  notification,
  Checkbox,
} from 'antd';
import {
  DigitalSignTutorialModal,
  Drawer,
  SignatureProcess,
  SuccessMessage,
  TutorialModal,
  UploadCard,
} from 'components';
import './styles.less';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import {
  useMutation,
  useQuery,
} from '@apollo/client';
import { formatError, formatAndNotificateError } from 'utils';
import useDrawerHelpers from 'utils/hooks/useDrawerHelpers';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/sdk';
import {
  CREATE_VERIFICATION_CODE,
  SAVE_SIGNATURE,
  VERIFY_CODE,
  SAVE_OPTIN,
  SETTINGS,
} from '../../graphql';
import { meVar } from '../../../../../services/graphql/cache';
import { SAVE_CCB_LOAN, SAVE_LOAN_EVENT, SIGNATURE_TYPE } from './graphql';

const BadesulSignatureDrawer = ({
  onClose,
  onCompleted,
  visible,
  loan,
  refetch,
  isBadesc,
}) => {
  const history = useHistory();
  const carouselRef = useRef(null);
  const [jsonIpLoading, setJsonIpLoading] = useState(false);
  const [carouselStep, setCarouselStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [acceptedCheckbox, setAcceptedCheckbox] = useState(false);
  const [acceptedAvalistCheckbox, setAcceptedAvalistCheckbox] = useState(false);
  const [allSignaturesCheckbox, setAllSignaturesCheckbox] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [alreadySigned, setAlreadySigned] = useState(false);
  const [digitalSignature, setDigitalSignature] = useState();
  const [digitalSignatureNonce, setDigitalSignatureNonce] = useState();
  const [digitalSignatureError, setDigitalSignatureError] = useState();
  const [ccbUrl, setCcbUrl] = useState();

  const [govModalVisible, setGovModalVisible] = useState(false);
  const [tutorialModalVisible, setTutorialModalVisible] = useState(false);

  const { goToStepSafely } = useDrawerHelpers({ carouselRef });

  const toSign = () => {
    if (loan) {
      return loan.signatures
        ?.filter(({ user }) => user !== null)
        .find(({ user: { id } = {} }) => meVar().id === id);
    }
    return null;
  };

  const {
    data: { settings: { signatureByFaceMatch } = {} } = {},
    loading: signatureByFaceMatchLoading,
  } = useQuery(SETTINGS);

  const [createVerificationCode] = useMutation(CREATE_VERIFICATION_CODE);
  const [saveOptIn] = useMutation(SAVE_OPTIN, {
    onCompleted: () => {
      setJsonIpLoading(false);
    },
    onError: (error) => {
      setJsonIpLoading(false);
      const formated = formatError(error);
      notification.error({ message: formated });
    },
  });
  const [saveSignature, { loading: signatureLoading }] = useMutation(SAVE_SIGNATURE, {
    onCompleted: () => {
      if (process.env.ENVIRONMENT === 'production' && loan) {
        ReactGA.event({
          category: 'Signature',
          action: 'Signed Contract',
        });
        ReactPixel.trackCustom('Subscribe', {
          action: 'Signed Contract',
          value: loan?.totalAmount,
        });
      }

      notification.destroy();
      notification.success({ message: 'Sua assinatura foi confirmada com sucesso.' });

      setCarouselStep(carouselStep + 1);
      setAlreadySigned(true);
      setJsonIpLoading(false);
      carouselRef?.current?.next();
    },
    onError: (error) => {
      setJsonIpLoading(false);
      setDigitalSignature();
      setDigitalSignatureNonce();

      const formated = formatError(error);
      if (formated === 'Tempo para finalização de assinatura excedido. Tente novamente. Se o problema persistir, entre em contato com o suporte'
        || formated === 'Esse e-CPF já foi utilizado em uma assinatura deste contrato') {
        setDigitalSignatureError(formated);
      } else {
        notification.error({
          message: formated,
        });
      }
    },
  });
  const [signatureType, { loading: typeLoading }] = useMutation(SIGNATURE_TYPE, {
    onCompleted: () => { },
    onError: (error) => notification.error({ message: formatError(error) }),
  });
  const [saveLoanEvent, { loading: eventLoading }] = useMutation(SAVE_LOAN_EVENT, {
    onCompleted: () => {
      notification.success({ message: 'Sua assinatura foi confirmada com sucesso.' });
      setCarouselStep(carouselStep + 1);
      setAlreadySigned(true);
      carouselRef?.current?.next();
      setGovModalVisible(false);
      refetch();
    },
    onError: (error) => notification.error({ message: formatError(error) }),
  });
  const [saveCcbLoan, { loading: ccbLoading }] = useMutation(SAVE_CCB_LOAN, {
    onCompleted: () => { },
    onError: (error) => notification.error({ message: formatError(error) }),
  });

  const handleIpFailure = () => {
    notification.error({
      message: 'Não conseguimos localizar seu IP. '
        + 'Por favor tente se desconectar do Wi-Fi ou tentar em outro navegador.',
      duration: 30,
    });
    setJsonIpLoading(false);
  };

  const [verifyCode, { loading: verificationLoading }] = useMutation(VERIFY_CODE, {
    onCompleted: () => {
      setCurrentStep(2);
      return true;
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const _onClose = () => {
    setCarouselStep(0);
    setCurrentStep(0);
    refetch();
    if (onCompleted) onCompleted();
    onClose?.();
  };

  useEffect(() => {
    goToStepSafely(carouselStep);
  }, [carouselStep]);

  const getPrimaryButtonProps = () => {
    if (alreadySigned) {
      return null;
    }

    const baseProps = {
      disabled: !acceptedCheckbox || !acceptedAvalistCheckbox,
      loading: signatureLoading
        || verificationLoading
        || jsonIpLoading
        || signatureByFaceMatchLoading,
    };

    // Confirmar assinatura
    if (currentStep === 0) {
      return {
        ...baseProps,
        id: 'sign-loan-button',
        onClick: async () => {
          if (!window.BryExtension) {
            return setTutorialModalVisible(true);
          }

          try {
            await window.BryExtension.isNativeModuleUpdated();
          } catch (error) {
            return window.BryExtension.promptUserToInstallNativeModule();
          }

          createVerificationCode();

          setJsonIpLoading(true);
          fetch('https://api.ipify.org/?format=json')
            .then((response) => response.json())
            .then(({ ip }) => {
              saveOptIn({
                variables: {
                  ip,
                  accepted: acceptedAvalistCheckbox,
                },
              });
              setCurrentStep(currentStep + 1);
            })
            .catch(handleIpFailure);

          return true;
        },
        children: 'Assinar',
      };
    }

    // Inserir código sms
    if (currentStep === 1) {
      return {
        ...baseProps,
        id: 'verificate-code-button',
        onClick: async () => {
          await verifyCode({
            variables: {
              verificationCode: verificationCode.toString(),
            },
          });
        },
        children: 'Prosseguir',
      };
    }

    // Certificado digital
    if (currentStep === 2) {
      return {
        ...baseProps,
        id: 'certificate-button',
        disabled: !digitalSignature || !digitalSignatureNonce,
        onClick: async () => {
          setJsonIpLoading(true);
          fetch('https://api.ipify.org/?format=json')
            .then((response) => response.json())
            .then(({ ip }) => {
              saveSignature({
                variables: {
                  ip,
                  id: toSign()?.id,
                  verificationCode,
                  digitalSignature: digitalSignature.signature,
                  cpf: digitalSignature.cpf,
                  nonce: digitalSignatureNonce,
                },
              });
            })
            .catch(handleIpFailure);
        },
        children: 'Concluir',
      };
    }

    return null;
  };

  useEffect(() => {
    const signatures = loan?.signatures || [];
    const mySignature = signatures.find((signature) => signature?.user?.id === meVar()?.id);
    if (!mySignature) {
      return;
    }

    if (mySignature.status.value === 'signed') {
      setAlreadySigned(true);
    }

  }, [loan]);

  return (
    <Drawer
      id="badesul-signature-drawer"
      visible={visible}
      maxWidth="90.5rem"
      onClose={() => {
        if (carouselStep === 1) refetch();
        setCurrentStep(0);
        onClose();
      }}
      className="badesul-signature-drawer"
      title={carouselStep !== 1 ? 'Assinar contrato' : null}
      primaryButton={getPrimaryButtonProps()}
      extraFooterComponents={currentStep === 0 && (
        <Button
          className="print-button"
          color="secondary"
          onClick={() => setGovModalVisible(true)}
        >
          Assinar pelo GOV
        </Button>
      )}
    >
      <div className="drawer-body">
        <Carousel
          ref={(ref) => {
            carouselRef.current = ref;
          }}
          draggable={false}
          swipe={false}
          swipeToSlide={false}
          dots={false}
          accessibility={false}
          infinite={false}
        >
          <div className="carousel-content">
            {carouselStep === 0 && (
              <SignatureProcess
                alreadySigned={alreadySigned}
                setAlreadySigned={setAlreadySigned}
                currentStep={currentStep}
                loan={loan}
                setCurrentStep={(e) => setCurrentStep(e)}
                acceptedCheckbox={acceptedCheckbox}
                setAcceptedCheckbox={(e) => setAcceptedCheckbox(e)}
                acceptedAvalistCheckbox={acceptedAvalistCheckbox}
                setAcceptedAvalistCheckbox={(e) => setAcceptedAvalistCheckbox(e)}
                verificationCode={verificationCode}
                setVerificationCode={(e) => setVerificationCode(e)}
                setDigitalSignature={(e) => setDigitalSignature(e)}
                setDigitalSignatureNonce={(e) => setDigitalSignatureNonce(e)}
                digitalSignatureError={digitalSignatureError}
                setDigitalSignatureError={(e) => setDigitalSignatureError(e)}
                signatureLoading={signatureLoading || verificationLoading || jsonIpLoading}
                next={() => {
                  setCarouselStep(carouselStep + 1);
                }}
                isBadesc={isBadesc}
                signatureByFaceMatch={signatureByFaceMatch}
                signatureByFaceMatchLoading={signatureByFaceMatchLoading}
              />
            )}
          </div>

          <div className="carousel-content">
            {carouselStep === 1 && (
              <Row>
                <Col span={24}>
                  <SuccessMessage
                    title="Você assinou o contrato."
                    centered
                    subtitle={(
                      <>
                        Esse processo
                        {' '}
                        <b>levará algum tempo</b>
                        , e todos
                        {' '}
                        <b>os sócios deverão assinar </b>
                        {' '}
                        o contrato.
                        <br />
                        <b>Você receberá a resposta por email.</b>
                      </>
                    )}
                    primaryButton={!isBadesc && ({
                      text: 'Ver lista de empréstimos',
                      onClick: () => {
                        _onClose();
                        history.push('/emprestimos');
                      },
                    })}
                    secondaryButton={{
                      text: 'Ir para Página Inicial',
                      onClick: () => {
                        onClose();
                        history.push('/');
                      },
                    }}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Carousel>
      </div>

      <DigitalSignTutorialModal
        visible={tutorialModalVisible}
        onClose={() => setTutorialModalVisible(false)}
      />

      <TutorialModal
        visible={govModalVisible}
        onClose={() => setGovModalVisible(false)}
        title="O que fazer agora?"
        steps={[
          {
            title: 'Salvar',
            nextButtonText: 'Já salvei o contrato',
            content: (
              <>
                <ul>
                  <li>Clique no botão abaixo para abrir o contrato</li>
                  <li>Salve o contrato para realizar a assinatura na plataforma GOV</li>
                </ul>

                <Button
                  block
                  onClick={() => window.open(loan.ccb.url, '_blank')}
                  link
                >
                  Abrir contrato
                </Button>
              </>
            ),
          },
          {
            title: 'Assinar',
            nextButtonText: 'Já assinei o contrato',
            content: (
              <ul>
                <li>Realize a assinatura digital na plataforma GOV</li>
              </ul>
            ),
          },
          {
            title: 'Enviar',
            nextButtonText: 'Confirmar envio',
            nextButtonLoading: typeLoading || ccbLoading || eventLoading,
            nextButtonDisabled: !ccbUrl || !allSignaturesCheckbox,
            nextAction: async (resolve) => {
              await signatureType({
                variables: {
                  loanId: loan.id,
                  typeValue: 'gov',
                },
              });

              await saveCcbLoan({
                variables: {
                  id: loan.id,
                  ccbFileId: ccbUrl,
                },
              });

              await saveLoanEvent({
                variables: {
                  loanId: loan.id,
                  statusValue: 'signed',
                },
              });

              resolve();
            },
            content: (
              <>
                <ul>
                  <li>
                    Faça o upload do contrato e nos envie
                  </li>
                </ul>

                <UploadCard
                  id="uploadCcbDocumentId"
                  accept="application/pdf, .pdf"
                  label="Documento CCB"
                  onCompleted={async ({ id: fileId }) => setCcbUrl(fileId)}
                  validateSignature
                />

                <Checkbox
                  style={{
                    marginTop: 8,
                  }}
                  checked={allSignaturesCheckbox}
                  id="ccb-checkbox"
                  onChange={({ target: { checked } }) => setAllSignaturesCheckbox(checked)}
                >
                  <p>
                    <b>
                      Confirmo que o PDF está assinado por todas as partes do contrato
                    </b>
                  </p>
                </Checkbox>
              </>
            ),
          },
        ]}
      />
    </Drawer>
  );
};

BadesulSignatureDrawer.propTypes = {
  onClose: PropTypes.func,
  onCompleted: PropTypes.func,
  refetch: PropTypes.func,
  visible: PropTypes.bool,
  loan: PropTypes.shape({
    id: PropTypes.string,
    totalAmount: PropTypes.number,
    signatures: PropTypes.array,
    additive: PropTypes.shape({
      url: PropTypes.string,
    }),
    status: PropTypes.shape(),
    bankerId: PropTypes.string,
    ccb: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  isBadesc: PropTypes.bool,
};

export default BadesulSignatureDrawer;
