import React from 'react';
import PropTypes from 'prop-types';
import BadesulSignatureProcess from './components/BadesulSignatureProcess';
import DefaultSignatureProcess from './components/DefaultSignatureProcess';

const SignatureProcess = ({
  loan,
  ...rest
}) => {
  const isBadesul = loan?.portfolioName?.toLowerCase().includes('badesul');

  const handleSignatureProcess = () => {
    if (loan && isBadesul) {
      return (
        <BadesulSignatureProcess
          loan={loan}
          {...rest}
        />
      );
    }

    if (loan && loan.portfolioName) {
      return (
        <DefaultSignatureProcess
          loan={loan}
          {...rest}
        />
      );
    }

    return null;
  };

  return (
    <>
      {handleSignatureProcess()}
    </>
  );
};

SignatureProcess.propTypes = {
  next: PropTypes.func,
  currentStep: PropTypes.number,
  acceptedCheckbox: PropTypes.bool,
  setAcceptedCheckbox: PropTypes.func,
  acceptedAvalistCheckbox: PropTypes.bool,
  setAcceptedAvalistCheckbox: PropTypes.func,
  verificationCode: PropTypes.string,
  setVerificationCode: PropTypes.func,
  setEthnicGroup: PropTypes.func,
  signatureLoading: PropTypes.bool,
  alreadySigned: PropTypes.bool,
  loan: PropTypes.shape(),
  isBadesc: PropTypes.bool,
  setDigitalSignature: PropTypes.func,
  setDigitalSignatureNonce: PropTypes.func,
  digitalSignatureError: PropTypes.string,
  setDigitalSignatureError: PropTypes.func,
  signatureByFaceMatchLoading: PropTypes.bool,
  signatureByFaceMatch: PropTypes.bool,
};
export default SignatureProcess;
