import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Select,
  Row,
  Tooltip,
  Col,
} from 'antd';
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Input,
} from 'components/gyramais';
import {
  useQuery,
} from '@apollo/client';
import { formatDate } from 'utils';
import {
  BUSINESS_USER_TYPE,
  CIVIL_STATUSES,
} from 'components/forms/NewPartnerForm/graphql';

const { Option } = Select;
const { Item } = Form;
const genders = [
  { id: 1, name: 'Masculino', value: 'MASCULINO' },
  { id: 2, name: 'Feminino', value: 'FEMININO' },
];

const BadesulPronampeNewPartnerForm = ({
  form,
  setCivilStatus,
  businessUser,
  civilStatus,
}) => {
  const {
    data: {
      civilStatuses = [],
    } = {},
  } = useQuery(CIVIL_STATUSES, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      businessUserTypes = [],
    } = {},
  } = useQuery(BUSINESS_USER_TYPE, {
    fetchPolicy: 'cache-and-network',
  });

  const handleCivilStatusChange = (id) => {
    setCivilStatus(
      civilStatuses
        .find((civilStatus) => civilStatus.id === id).value,
    );
  };

  useEffect(() => {
    if (!businessUser?.id) {
      form.setFieldsValue({
        cpf: businessUser?.cpf,
        typesValues: [...new Set(['avalista'].concat(businessUser?.types || []))],
      });
      return;
    }

    const email = businessUser?.user?.email;
    const isEmail = email?.includes('@');

    form.setFieldsValue({
      ...businessUser?.user,
      ...businessUser?.user?.additionalInfo,
      birthDate: businessUser?.user?.birthDate
        && formatDate(new Date(businessUser?.user?.birthDate)),
      email: isEmail ? email : '',
      typesValues: businessUser?.types?.filter((type) => !['conjuge'].includes(type.value)).map((type) => type.value).sort(),
      civilStatusId: businessUser?.user?.civilStatus?.id,
    });

    if (businessUser?.user?.civilStatus) {
      setCivilStatus(businessUser.user.civilStatus.value);
    }
  }, [businessUser]);

  return (
    <Form
      layout="vertical"
      form={form}
      className="new-partner-form"
    >
      <Row gutter={12}>
        <Col xs={24} md={12}>
          <Item
            name="fullName"
            rules={[{ required: true, message: 'Por favor, insira o nome completo' }]}
          >
            <Input placeholder="Nome completo" />
          </Item>
        </Col>

        <Col xs={24} md={12}>
          <Item
            name="cpf"
            rules={[{ required: true, message: 'Por favor, insira o numero do CPF' }]}
          >
            <Input
              type="masked"
              guide
              placeholder="CPF"
              disabled={businessUser?.user?.cpf?.length > 0}
              mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            />
          </Item>
        </Col>

        <Col xs={24} md={12}>
          <Item
            name="email"
            rules={[{ required: true, message: 'Por favor, insira o endereço de email' }]}
          >
            <Input
              placeholder="Email"
              alert="Certifique-se de estar utilizando o email PESSOAL do sócio. Este email será verificado e utilizado para realização da assinatura na próxima etapa."
            />
          </Item>
        </Col>

        {!businessUser?.id && (
          <Col xs={24} md={12}>
            <Item
              name="confirmEmail"
              rules={[{ required: true, message: 'Por favor, confirme o endereço de email' }]}
            >
              <Input
                placeholder="Email"
              />
            </Item>
          </Col>
        )}

        <Col xs={24} md={6}>
          <Item
            name="phoneNumber"
            rules={[{ required: true, message: 'Por favor, insira o numero de Telefone' }]}
          >
            <Input
              type="masked"
              guide
              alert="Certifique-se de estar utilizando o Telefone celular PESSOAL do sócio. Este telefone celular será verificado e utilizado para realização da assinatura na próxima etapa."
              placeholder="Telefone celular"
              mask="phone"
            />
          </Item>
        </Col>

        <Col xs={24} sm={24} md={6}>
          <Item
            name="birthDate"
            rules={[{ required: true, message: 'Insira a data de nascimento' }]}
          >
            <Input
              type="masked"
              guide
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              placeholder="Data de nascimento"
            />
          </Item>
        </Col>

        <Col xs={24} md={12} className="civil-status-col">
          {['solteiro', 'uniao-estavel'].includes(civilStatus) && (

            <Tooltip
              placement="bottomLeft"
              title={(
                <span>
                  Será gerado um documento assinado pelo seu IP com o estado civil informado.
                </span>
              )}
            >
              <ExclamationCircleOutlined className="information-icon" />
            </Tooltip>
          )}
          {civilStatus === 'casado' && (
            <Tooltip
              placement="bottomLeft"
              title={(
                <span>
                  O cônjuge será avalista do empréstimo
                </span>
              )}
            >
              <ExclamationCircleOutlined className="information-icon" />
            </Tooltip>
          )}

          <Item
            name="civilStatusId"
            rules={[{ required: true, message: 'Por favor, insira o estado civil' }]}
          >
            <Select
              id="select-civil-status"
              placeholder="Estado Civil"
              className="gyramais-input"
              dropdownClassName="gyramais-input-options"
              onChange={handleCivilStatusChange}
            >
              {civilStatuses.map((i) => (
                <Option value={i.id} key={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col span={0}>
          <Item
            name="typesValues"
            rules={[{ required: true, message: 'Por favor, adicione ao menos uma responsabilidade' }]}
          >
            <Select
              disabled
              className="gyramais-input"
              mode="multiple"
              placeholder="Selecione as responsabilidades"
              dropdownClassName="gyramais-input-options"
              onChange={(e) => {
                if (!e?.includes('avalista')) {
                  const values = form.getFieldsValue().typesValues || [];
                  form.setFieldsValue({
                    typesValues: ['avalista', ...values],
                  });
                }
              }}
            >
              {businessUserTypes.filter((i) => !['conjuge'].includes(i.value)).map((i) => (
                <Option value={i.value} key={i.id}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col xs={24} sm={24} lg={12}>
          <Item
            name="gender"
            rules={[{ required: true, message: 'Selecione o sexo' }]}
          >
            <Select
              className="gyramais-input"
              placeholder="Selecione o sexo"
            >
              {genders.map(({ id, name, value }) => (
                <Option key={id} value={value}>{name}</Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

BadesulPronampeNewPartnerForm.propTypes = {
  form: PropTypes.shape(),
  businessUser: PropTypes.shape(),
  setCivilStatus: PropTypes.func,
  civilStatus: PropTypes.string,
};

export default BadesulPronampeNewPartnerForm;
