/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  notification,
} from 'antd';
import {
  UploadCard,
} from 'components';
import {
  Card,
  Drawer,
  Input,
} from 'components/gyramais';
import {
  Button,
  Modal,
} from 'components/sdk';
import {
  translateMonth,
} from 'utils';
import './styles.less';

const { Item } = Form;

const businessFile = {
  '204-6': 'Estatuto social',
  '205-6': 'Estatuto social',
  '206-2': 'Contrato social',
  '213-5': 'Certificado da condição do MEI ou Registro de requisição EI ou Declaração de incorporação da EIRELLI',
  '223-2': 'Contrato social',
  '224-0': 'Contrato social',
  '230-5': 'Declaração de incorporação EIRELI',
  '231-5': 'Declaração de incorporação EIRELI',
  '401-4': 'Requisição de registro EI',
};

const BusinessUploadForm = ({
  files: {
    proofOfAddress,
    bylaws,
    revenueStatement,
    cadastur,
    images,
    majorityPartnerSelfie,
  } = {},
  statements: businessStatements,
  form,
  type,
  refetch,
  flow,
  line,
  validateStatements,
  segment,
  necessaryDocumentsFlow,
  lemitValidated,
  address,
  hideNotAnalyzedFiles,
  businessDocumentsRevised,
  setBusinessDocumentsRevised,
}) => {
  const [alertStatementModal, setAlertStatementModal] = useState(false);
  const [statementDrawer, setStatementDrawer] = useState(false);
  const [statements, setStatements] = useState([]);
  useEffect(() => {
    form.setFieldsValue({
      bylawsFileId: bylaws?.id,
      proofOfAddressFileId: proofOfAddress?.id,
      revenueStatementFilesIds: revenueStatement?.length > 0
        ? revenueStatement.map(({ id }) => id)
        : [],
      cadasturFileId: cadastur?.id,
      majorityPartnerSelfieFileId: majorityPartnerSelfie?.id,
      imagesFilesIds: images?.length > 0
        ? images.map(({ id }) => id)
        : [],
    });
  }, [proofOfAddress, bylaws, revenueStatement, cadastur]);
  useEffect(() => {
    const emptyStatements = [];
    for (let x = 1; x <= 24; x++) {
      const auxDate = new Date();
      const date = new Date(auxDate.getFullYear(), auxDate.getMonth() - x, 1, 0, 0, 0, 0);
      const mesano = `${date.getMonth() + 1}${date.getFullYear()}`;
      const statement = businessStatements
        ?.find(({ mesano: statementMesano }) => statementMesano === mesano);
      emptyStatements.push({
        id: x,
        label: `${translateMonth(date.getMonth())} de ${date.getFullYear()}`,
        reference: new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`),
        value: statement?.value || 0,
        mesano,
      });
    }
    setStatements(emptyStatements.reverse());
    form.setFieldsValue({
      revenueStatementValues: emptyStatements
        .map(({ value, reference, mesano }) => ({ value, reference: new Date(reference), mesano })),
    });
  }, [businessStatements]);

  const handleRevenueStatement = async () => {
    const values = form.getFieldsValue();
    for (const statement of statements) {
      if (statement.value === null || statement.value === undefined) {
        return notification.error({ message: 'Preencha ao menos 6 meses de faturamento' });
      }
    }
    if (!values.revenueStatementFilesIds || values.revenueStatementFilesIds.length === 0) {
      return notification.error({ message: 'Insira os arquivos que comprovam os valores descritos como faturamento' });
    }

    const isStatementsValidated = validateStatements(statements);
    if (isStatementsValidated) {
      await form.setFieldsValue({
        revenueStatementValues: statements
          .map(({ value, reference, mesano }) => ({ value, reference: new Date(reference), mesano })),
      });
      setStatementDrawer(false);
      setAlertStatementModal(false);
      return true;
    }
    return setAlertStatementModal(true);
  };

  return (
    <>
      <Form
        className="form-footer"
        form={form}
        layout="vertical"
      >
        {/* hideNotAnalyzedFiles */}
        <Row gutter={[0, 20]}>
          {(bylaws?.analysis?.approved === false || !hideNotAnalyzedFiles) && (
            <Col span={24} className="bylaws-upload">
              <Item name="bylawsFileId" rules={[{ required: true, message: 'Por favor, insira o documento contratual' }]}>
                <UploadCard
                  id="bylaws-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={bylaws}
                  alert={(businessFile[type] === 'Contrato social' || businessFile[type] === 'Estatuto social')
                    && 'O documento precisa estar completo, com assinaturas e carimbos da junta comercial.'}
                  label={`${businessFile[type] || 'Documento contratual'} da empresa`}
                  actionButtonText={bylaws?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={bylaws?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ bylawsFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {((!hideNotAnalyzedFiles && (necessaryDocumentsFlow !== 'B' || !lemitValidated)) || (hideNotAnalyzedFiles && address?.analysis?.approved === false)) && (
            <Col span={24} className="proofOfAddressFileId">
              <Item name="proofOfAddressFileId" rules={[{ required: true, message: 'Por favor, insira o comprovante de endereço' }]}>
                <UploadCard
                  id="proof-of-address-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={proofOfAddress}
                  alert="Escolha uma das opções: Conta de luz, água, gás, telefone ou internet, com no máximo 90 dias, ou contrato de locação do imóvel. Enviar conta aberta. Não aceitamos somente a capa."
                  label="Comprovante de endereço"
                  actionButtonText={address?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={address?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ proofOfAddressFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {flow !== 'badesc' && !hideNotAnalyzedFiles && (
            <>
              {segment !== '2' && (
                <Col span={24} className="imagesFilesIds">
                  <Item name="imagesFilesIds">
                    <UploadCard
                      id="images-upload"
                      accept="image/png,image/jpeg"
                      actualFile={images}
                      multiples
                      refetch={refetch}
                      label="Fotos da fachada, do interior e do estoque do seu negócio (opcional)"
                      onCompleted={async ({ id }) => {
                        const ids = form.getFieldValue('imagesFilesIds');
                        ids.push(id);

                        await form.setFieldsValue({ imagesFilesIds: ids });
                      }}
                      onDelete={async ({ id }) => {
                        const ids = form.getFieldValue('imagesFilesIds');
                        const newArrayOfIds = [];
                        ids.map((index) => index !== id && newArrayOfIds.push(index));

                        await form.setFieldsValue({ imagesFilesIds: newArrayOfIds });
                      }}
                    />
                  </Item>
                </Col>
              )}

              {necessaryDocumentsFlow !== 'B' && !hideNotAnalyzedFiles && (
                <Col span={24} className="majorityPartnerSelfieFileId">
                  <Item name="majorityPartnerSelfieFileId">
                    <UploadCard
                      id="selfie-major-upload"
                      accept="image/png,image/jpeg,application/pdf"
                      actualFile={majorityPartnerSelfie}
                      label="Selfie do sócio majoritário com documento de identidade (opcional)"
                      onCompleted={async ({ id }) => {
                        await form.setFieldsValue({ majorityPartnerSelfieFileId: id });
                      }}
                    />
                  </Item>
                </Col>
              )}
            </>
          )}

          {flow === 'badesc' && !hideNotAnalyzedFiles && (
            <>
              {line === 'FUNGETUR SC MAIS RENDA EMPRESARIAL' && (
                <Col span={24}>
                  <Item name="cadasturFileId" rules={[{ required: true, message: 'Por favor, insira certificado CADASTUR' }]}>
                    <UploadCard
                      id="cadastur-upload"
                      accept="application/pdf"
                      actualFile={cadastur}
                      label="Certificado CADASTUR"
                      alert={(
                        <div>
                          Por favor, obtenha o certificado através do site
                          <a className="link-in-line-text" href="https://cadastur.turismo.gov.br/" target="_blank" rel="noreferrer">https://cadastur.turismo.gov.br/</a>
                          para dar prosseguimento à esta etapa.
                        </div>
                      )}
                      onCompleted={async ({ id }) => {
                        await form.setFieldsValue({ cadasturFileId: id });
                      }}
                    />
                  </Item>
                </Col>
              )}
              <Col span={24}>
                <Card className="gyra-statement-card">
                  <Row align="middle">
                    <Col xs={24} sm={24} md={12}>
                      <span className="gyra-statement-label">
                        Faturamento dos últimos 24 meses
                      </span>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <div className="gyra-statement-action">
                        <Button onClick={() => setStatementDrawer(true)}>Adicionar</Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Item className="hidden" name="revenueStatementFilesIds" />
              <Item className="hidden" name="revenueStatementValues" />
            </>
          )}
        </Row>
      </Form>

      <Drawer
        title="Faturamento dos últimos 24 meses"
        visible={statementDrawer}
        primaryButton={{
          children: 'Confirmar',
          onClick: handleRevenueStatement,
        }}
        onClose={() => setStatementDrawer(false)}
      >
        <Card className="statements-inputs-card">
          {statements.map(({ label, id: index, value }) => (
            <Row align="middle" key={index} className="statement-input-area" gutter={[24, 24]}>
              <Col xs={24} sm={24} md={12}>
                <div className="statment-label">{`Valor em ${label}`}</div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Input
                  type="currency"
                  defaultValue={value}
                  onChange={async (value) => {
                    const statementId = statements.findIndex(({ id }) => id === index);
                    const all = statements;
                    all[statementId].value = value;
                    setStatements(all);
                  }}
                />
              </Col>
            </Row>
          ))}
        </Card>

        <UploadCard
          id="revenue-statement-upload"
          accept="application/pdf, .pdf"
          actualFile={revenueStatement}
          multiples
          refetch={refetch}
          alert="Relação de faturamento dos últimos 24 meses, mês a mês, assinado por certificado digital pelo contador, sob carimbo e registro no Conselho Regional de Contabilidade (CRC)."
          label="Faturamento dos últimos 24 meses"
          validateSignature
          onCompleted={async ({ id }) => {
            const ids = form.getFieldValue('revenueStatementFilesIds');
            ids.push(id);

            await form.setFieldsValue({ revenueStatementFilesIds: ids });
          }}
          onDelete={async ({ id }) => {
            const ids = form.getFieldValue('revenueStatementFilesIds');
            const newArrayOfIds = [];
            ids.map((index) => index !== id && newArrayOfIds.push(index));

            await form.setFieldsValue({ revenueStatementFilesIds: newArrayOfIds });
          }}
        />
      </Drawer>

      <Modal
        visible={alertStatementModal}
        okButton={{
          text: 'Voltar e preencher',
          onClick: () => setAlertStatementModal(false),
        }}
        title="OPS!!!"
        closable={false}
      >
        <p>
          Parece que você não preencheu todos os campos necessários para continuar sua solicitação. Confira se todos os meses de faturamento foram preenchidos corretamente e clique em CONFIRMAR.
          <br />
          <br />
          Lembrete: Para ter acesso ao crédito as empresas precisam ter, no mínimo, seis meses de faturamento.
          <br />
          <br />
          Em caso de dúvida, peça apoio ao Suporte.
        </p>
      </Modal>
    </>
  );
};

BusinessUploadForm.propTypes = {
  form: PropTypes.shape(),
  files: PropTypes.shape(),
  type: PropTypes.string,
  flow: PropTypes.string,
  line: PropTypes.string,
  refetch: PropTypes.func,
  statements: PropTypes.arrayOf(PropTypes.shape()),
  validateStatements: PropTypes.func,
  segment: PropTypes.string,
  necessaryDocumentsFlow: PropTypes.string,
  lemitValidated: PropTypes.bool,
  hideNotAnalyzedFiles: PropTypes.bool,
  address: PropTypes.shape(),
  setBusinessDocumentsRevised: PropTypes.func,
  businessDocumentsRevised: PropTypes.arrayOf(PropTypes.shape()),
};

export default BusinessUploadForm;
