/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  Collapse,
  Tooltip,
} from 'antd';
import {
  Status,
  Information,
  Alert,
} from 'components/gyramais';
import {
  Button,
} from 'components/sdk';
import {
  InstallmentRow,
  ScheduleInstallmentsModal,
} from 'components';
import { interestRateByPortfolio } from 'utils';
import { meVar } from 'services/graphql/cache';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import PayInvoiceModal from 'components/modals/PayInvoiceModal';
import ContractButton from '../ContractButton';

import './styles.less';

const { Panel } = Collapse;

const BadescLoanCard = ({
  loan: {
    id,
    number,
    status: {
      name: statusName,
      value: statusValue,
      parent,
    } = {},
    amount,
    finalAmount,
    installmentsCount,
    finalInstallmentsCount,
    installments = [],
    firstPaymentDate,
    interestRate,
    ccb,
    debitAuthorization,
    authorizationTerm,
    additive,
    partner,
    bankerId,
    bankerValue,
    signatures,
    couponStatusName,
    beforeCouponValues,
    downPaymentInvoice,
    portfolioName,
  },
  detailed,
  refetch,
  onClickSign,
  index,
}) => {
  const { actions, checkActions, loadingActions } = usePartnerActionsContext();

  const [isCollapseClosed, setCollapse] = useState(true);
  const [downPaymentInvoiceVisible, setDownPaymentInvoiceVisible] = useState(false);
  const [needToSign, setNeedToSign] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

  const isInstallmentLate = (installment) => installment && installment.values.penalty.debitDays > 0 && installment.values.status.value.includes('late');
  const isInstallmentTheNextOne = (installment) => installment && installment.values.penalty.debitDays === 0 && installment.values.status.value === 'pending';
  const isInstallmentPaid = (installment) => installment && installment.values.status.value === 'paid';

  const lateInstallments = installments.filter(isInstallmentLate);
  const paidInstallments = installments.filter(isInstallmentPaid);
  const pendingInstallments = installments.filter(
    (installment) => !isInstallmentLate(installment) && !isInstallmentPaid(installment) && !installment?.isDiscount,
  );

  const nextPendingInstallment = installments.find(isInstallmentTheNextOne);

  const firstLateInstallment = lateInstallments[0];
  const hasLateInstallments = Boolean(firstLateInstallment);

  const nextInstallmentToPay = firstLateInstallment || nextPendingInstallment;

  const renderInstallment = (installment, showLineDivider) => {
    const isLate = isInstallmentLate(installment);
    const isAbleToGetInvoice = hasLateInstallments
      ? isLate
      : installment.id === nextPendingInstallment.id;

    return (
      <div key={installment.id}>
        <InstallmentRow
          loading={loadingActions}
          actions={actions}
          installment={installment}
          last={showLineDivider}
          detailed
          refetch={refetch}
          withOptions={['active', 'active-renegotiated'].includes(statusValue)}
          late={isLate}
          isAbleToGetInvoice={isAbleToGetInvoice}
        />
      </div>
    );
  };

  const shouldShowDownPaymentInvoice = downPaymentInvoice && !downPaymentInvoice?.paidAt && statusValue !== 'canceled';

  const renderInstallmentList = (list) => (
    list.map(
      (installment, index) => renderInstallment(installment, index === list.length - 1),
    )
  );

  const showFinalValues = [
    'pending',
    'registered',
    'awaiting-signature',
    'signed',
    'active',
    'pending-renegotiated',
    'active-renegotiated',
    'renegotiated',
    'awaiting-signature-additive',
    'awaiting-payment',
  ].includes(statusValue);

  const showFee = [
    'pending',
    'pre-approved',
    'registered',
    'awaiting-signature',
    'signed',
    'awaiting-signature-additive',
    'awaiting-payment',
    'active-renegotiated',
    'lawsuit',
    'completed',
    'active',
  ].includes(statusValue);

  const showContracts = !shouldShowDownPaymentInvoice && detailed && [
    'awaiting-signature',
    'signed',
    'active',
    'awaiting-signature-additive',
    'active-renegotiated',
    'lawsuit',
  ].includes(statusValue);

  const showSignButton = !shouldShowDownPaymentInvoice && needToSign && [
    'awaiting-signature',
    'awaiting-signature-additive',
  ].includes(statusValue);

  const showScheduleInstallments = [
    'signed',
    'active',
    'active-renegotiated',
  ].includes(statusValue);

  useEffect(() => {
    if (meVar()) {
      const { id: userId } = meVar();
      const needToSign = signatures?.some(({ user }) => user?.id === userId);
      setNeedToSign(needToSign);
    }
  }, [signatures]);

  // let badescContext;
  // useEffect(() => {
  //   badescContext = contextBadesc(portfolioName);
  // }, [showFee]);

  // console.log('badescContext', badescContext);

  const getStatusValue = () => {
    if (shouldShowDownPaymentInvoice) {
      return 'down-payment-invoice';
    }

    if (['active', 'active-renegotiated'].includes(statusValue) && firstLateInstallment) {
      return 'late';
    }

    return parent?.value || statusValue;
  };

  const getStatusName = () => {
    if (shouldShowDownPaymentInvoice) {
      return 'ENTRADA PENDENTE';
    }

    if (['active', 'active-renegotiated'].includes(statusValue) && firstLateInstallment) {
      return 'Em atraso';
    }

    if (parent?.value === 'awaiting-re-evaluation') {
      return 'INDEFERIDA';
    }

    return parent?.name || statusName;
  };

  return (
    <>
      {showScheduleInstallments && (
        <ScheduleInstallmentsModal
          visible={scheduleModalOpen}
          onClose={() => setScheduleModalOpen(false)}
          installmentsCount={finalInstallmentsCount}
          installmentDate={firstPaymentDate}
          installments={installments}
        />
      )}

      <PayInvoiceModal
        visible={downPaymentInvoiceVisible}
        onClose={() => setDownPaymentInvoiceVisible(false)}
        invoiceId={downPaymentInvoice?.id}
        loanId={id}
      />

      {parent?.value === 'awaiting-re-evaluation'
        && window.location.pathname === '/'
        && (
          <Card className="loan-card aux-loan-card" data-cy="loan-card">
            <p>
              A análise é feita automaticamente pelo sistema e leva em consideração:
              <br />
              - Valor solicitado
              <br />
              - Situação cadastral
              <br />
              - Regularidade fiscal
              <br />
              - Faturamento e endividamento da empresa
              <br />
              <br />
              Lamentamos o indeferimento e esperamos fazer negócios com você em outra oportunidade!
            </p>
          </Card>
        )}

      <Card
        className={`loan-card ${detailed ? 'detailed' : ''}`}
        id={`loan-card-${index}`}
      >
        <Row align="middle" justify="space-between" gutter={[16, 16]}>
          <Col className="header-left-side">
            <div className="title-container">
              <h3 className="no-margin-bottom">
                Empréstimo #
                {number}
              </h3>
            </div>

            <Status
              value={getStatusValue()}
              name={getStatusName()}
            />
          </Col>
        </Row>

        <br />

        <div className="loan-card-body">
          <Row align="middle" className="top-side info-row" justify="space-between" gutter={12}>
            {detailed ? (
              <>
                <Col xs={24} sm={12} md={12} lg={5}>
                  <Information
                    size="large"
                    label="Valor"
                    value={`${(showFinalValues && finalAmount) || amount}`}
                    type="currency"
                  />
                </Col>

                <Col xs={24} sm={12} md={12} lg={4}>
                  <Information
                    size="large"
                    label="Prazo"
                    value={`${(
                      showFinalValues && finalInstallmentsCount)
                      || installmentsCount} meses
                    `}
                  />
                </Col>

                <Col xs={24} sm={12} md={12} lg={6}>
                  {showFee && (
                    <Information
                      size="large"
                      label="Taxa de Juros"
                      value={(
                        <div>
                          <Tooltip title="Variação diária da taxa Selic">
                            Selic* + {interestRateByPortfolio[portfolioName]} a.a.
                          </Tooltip>
                        </div>
                      )}
                      originalValue={couponStatusName !== 'Expirada' && beforeCouponValues?.interestRate && interestRate !== beforeCouponValues?.interestRate ? `${beforeCouponValues?.interestRate}` : null}
                    />
                  )}
                </Col>
              </>
           ) : (
             <>
               <Col xs={24} sm={12} md={12} lg={7}>
                 <Information
                   size="large"
                   label="Valor"
                   value={`${(showFinalValues && finalAmount) || amount}`}
                   type="currency"
                 />
               </Col>

               <Col xs={24} sm={12} md={12} lg={4}>
                 <Information
                   size="large"
                   label="Prazo"
                   value={`${(
                  showFinalValues && finalInstallmentsCount)
                  || installmentsCount} meses
                `}
                 />
               </Col>

               <Col xs={24} sm={12} md={12} lg={4}>
                 {showFee && (
                 <Information
                   size="large"
                   label="Taxa de Juros"
                   type={interestRate ? 'percentage' : null}
                   value={(interestRate || '-')}
                   originalValue={couponStatusName !== 'Expirada' && beforeCouponValues?.interestRate && interestRate !== beforeCouponValues?.interestRate ? `${beforeCouponValues?.interestRate}` : null}
                 />
              )}
               </Col>
             </>
        )}

            <Col xs={24} sm={24} md={24} lg={detailed ? 6 : 9}>
              <Row gutter={[16, 16]} className="buttons-area">
                {showContracts && (
                  <Col>
                    <ContractButton
                      additive={additive}
                      ccb={ccb}
                      debitAuthorization={debitAuthorization}
                      authorizationTerm={authorizationTerm}
                      bankerId={bankerId}
                      bankerValue={bankerValue}
                      flow={partner?.flow}
                      partner={partner}
                      statusValue={statusValue}
                    />
                  </Col>
                )}

                {shouldShowDownPaymentInvoice && (
                  <Col>
                    <Button className="pay-promo" onClick={() => setDownPaymentInvoiceVisible(true)} data-cy="loan-promo-payment-button">
                      Pagar entrada
                    </Button>
                  </Col>
                )}

                {showSignButton && (
                  <Col>
                    <Button onClick={onClickSign} id="loan-card-sign-loan-button">
                      {`Assinar ${statusValue === 'awaiting-signature'
                        ? `${['ideal'].includes(partner?.flow) || checkActions(['debit-authorization-term']) ? 'contratos' : 'contrato'}`
                        : 'aditivo'}`}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          {['active', 'active-renegotiated', 'awaiting-signature'].includes(statusValue)
            && installments[0]
            && (
              <>
                <hr className="section-divider" />

                {firstLateInstallment && (
                  <Alert
                    className="late-alert"
                    // eslint-disable-next-line max-len
                    message={`Você tem ${lateInstallments.length} parcelas em atraso. Regularize sua situação.`}
                    type="error"
                    color="red"
                  />
                )}

                <div className="bottom-side">
                  {detailed && (
                    <>

                      {isCollapseClosed && (
                        <>
                          {
                            isInstallmentLate(nextInstallmentToPay)
                              ? (
                                <h5 className="no-margin-bottom">
                                  <b className="danger-text">
                                    Próxima parcela
                                  </b>
                                </h5>
                              ) : (
                                <h5 className="no-margin-bottom">
                                  <b className="secondary-text">
                                    Próxima parcela
                                  </b>
                                </h5>
                              )
                          }

                          {nextInstallmentToPay && (
                            <InstallmentRow
                              loading={loadingActions}
                              actions={actions}
                              installment={nextInstallmentToPay}
                              last={isCollapseClosed}
                              refetch={refetch}
                              detailed
                              late={Boolean(firstLateInstallment)}
                              withOptions={['active', 'active-renegotiated'].includes(statusValue)}
                              isAbleToGetInvoice
                            />
                          )}
                        </>
                      )}

                      <Collapse activeKey={!isCollapseClosed ? 1 : undefined} ghost>
                        <Panel showArrow={false} key={1}>

                          {lateInstallments.length > 0 && (
                            <>
                              <h5 className="no-margin-bottom">
                                <b className="danger-text">
                                  Parcelas atrasadas
                                </b>
                              </h5>

                              {renderInstallmentList(lateInstallments)}
                              <br />
                            </>
                          )}

                          {paidInstallments.length > 0 && (
                            <>
                              <h5 className="no-margin-bottom">
                                <b className="secondary-text">
                                  Parcelas Pagas
                                </b>
                              </h5>

                              {renderInstallmentList(paidInstallments)}
                              <br />
                            </>
                          )}

                          {pendingInstallments.length > 0 && (
                            <>
                              <h5 className="no-margin-bottom">
                                <b className="secondary-text">
                                  Próximas parcelas
                                </b>
                              </h5>

                              {renderInstallmentList(pendingInstallments)}
                            </>
                          )}
                        </Panel>
                      </Collapse>

                      <hr />

                      <Button
                        onClick={() => setCollapse(!isCollapseClosed)}
                        link
                        className="collapse-installments-button"
                      >
                        {`${!isCollapseClosed ? 'Ocultar' : 'Ver'} todas as parcelas`}
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
        </div>
      </Card>
    </>
  );
};

BadescLoanCard.propTypes = {
  loan: PropTypes.shape(),
  detailed: PropTypes.bool,
  index: PropTypes.number,
  refetch: PropTypes.func,
  onClickSign: PropTypes.func,
  flow: PropTypes.string,
};

export default BadescLoanCard;
