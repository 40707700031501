import React from 'react';
import PropTypes from 'prop-types';
import {
  Steps, Row, Col,
} from 'antd';
import {
  LoanData,
  DigitalSignatureForm,
  SignatureForm,
} from 'components';
import './styles.less';

const { Step } = Steps;

const BadesulSignatureProcess = ({
  next,
  currentStep,
  setAcceptedCheckbox,
  acceptedCheckbox,
  acceptedAvalistCheckbox,
  setAcceptedAvalistCheckbox,
  signatureLoading,
  verificationCode,
  setVerificationCode,
  setDigitalSignature,
  setDigitalSignatureNonce,
  alreadySigned,
  loan,
  digitalSignatureError,
  setDigitalSignatureError,
}) => {

  const steps = [
    {
      title: 'Confirmar assinatura',
      content: (
        <LoanData
          acceptedCheckbox={acceptedCheckbox}
          setAcceptedCheckbox={setAcceptedCheckbox}
          acceptedAvalistCheckbox={acceptedAvalistCheckbox}
          setAcceptedAvalistCheckbox={setAcceptedAvalistCheckbox}
          alreadySigned={alreadySigned}
          loan={loan}
        />
      ),
    },
    {
      title: 'Inserir código',
      content: (
        <SignatureForm
          next={next}
          verificationCode={verificationCode}
          setVerificationCode={(e) => setVerificationCode(e)}
          signatureLoading={signatureLoading}
        />
      ),
    },
    {
      title: 'Assinatura digital',
      content: (
        <DigitalSignatureForm
          loan={loan}
          setDigitalSignature={(e) => setDigitalSignature(e)}
          setDigitalSignatureNonce={(e) => setDigitalSignatureNonce(e)}
          digitalSignatureError={digitalSignatureError}
          setDigitalSignatureError={(e) => setDigitalSignatureError(e)}
        />
      ),
    },
  ];

  return (
    <div className="badesul-signature-process">
      <Row>
        <Col xs={24} sm={24} md={24}>
          <Steps current={currentStep}>
            {steps.map(({ title }) => (
              <Step key={title} title={title} />
            ))}
          </Steps>
        </Col>
      </Row>

      {steps[currentStep].content && (
        <div className="steps-content">
          {steps[currentStep].content}
        </div>
      )}

    </div>
  );
};

BadesulSignatureProcess.propTypes = {
  next: PropTypes.func,
  currentStep: PropTypes.number,
  acceptedCheckbox: PropTypes.bool,
  setAcceptedCheckbox: PropTypes.func,
  acceptedAvalistCheckbox: PropTypes.bool,
  setAcceptedAvalistCheckbox: PropTypes.func,
  verificationCode: PropTypes.string,
  setVerificationCode: PropTypes.func,
  signatureLoading: PropTypes.bool,
  alreadySigned: PropTypes.bool,
  loan: PropTypes.shape(),
  setDigitalSignature: PropTypes.func,
  setDigitalSignatureNonce: PropTypes.func,
  digitalSignatureError: PropTypes.string,
  setDigitalSignatureError: PropTypes.func,
};
export default BadesulSignatureProcess;
