import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';
import { UploadCard, TermCard } from 'components';
import {
  DocumentExampleCNH,
  DocumentExampleRG,
  DocumentExampleIRPF1,
  DocumentExampleIRPF2,
  DocumentExampleIRPF3,
  DocumentExampleIRPF4,
  DocumentExampleIRPF5,
  DocumentExampleIRPF6,
  DocumentExampleIRPF7,
  DocumentExampleIRPF8,
  DocumentExampleIRPF9,
} from 'assets/images';

const { Item } = Form;

const cardIdExamples = [DocumentExampleRG, DocumentExampleCNH];
const irpfExamples = [
  DocumentExampleIRPF1,
  DocumentExampleIRPF2,
  DocumentExampleIRPF3,
  DocumentExampleIRPF4,
  DocumentExampleIRPF5,
  DocumentExampleIRPF6,
  DocumentExampleIRPF7,
  DocumentExampleIRPF8,
  DocumentExampleIRPF9,
];

const BadesulMicrocreditoDocumentsForm = ({
  form,
  civilStatus,
  businessUser: selectedBusinessUser,
  business,
  address,
  isCreation,
  loanStatus,
  finalAmount,
  capAmount,
  fromCooperativa,
}) => {
  const [idCard, setIdCard] = useState([]);
  const [proofOfIncome, setProofOfIncome] = useState([]);
  const [proofOfIncomeReceipt, setProofOfIncomeReceipt] = useState([]);
  const [proofOfAddress, setProofOfAddress] = useState([]);
  const [proofOfMariageOrDivorce, setProofOfMariageOrDivorce] = useState([]);
  const [proofOfWidower, setProofOfWidower] = useState([]);
  const [partnerIdCard, setPartnerIdCard] = useState([]);
  const [proofOfRealState, setProofOfRealState] = useState([]);

  useEffect(() => {
    if (selectedBusinessUser) {
      const {
        user: {
          idCard,
          proofOfIncome,
          proofOfIncomeReceipt,
          proofOfAddress,
          civilPartner = {},
          proofOfMariageOrDivorce,
          proofOfWidower,
          additionalFiles = [],
        },
      } = selectedBusinessUser;

      const { proofOfRealState } = additionalFiles.reduce((acc, file) => {
        if (file.parent?.name) {
          if (!acc[file.parent.name]) {
            acc[file.parent.name] = [];
          }

          acc[file.parent.name].push(file);
        }

        return acc;
      }, {});

      form.setFieldsValue({
        idCardFileId: idCard?.id,
        proofOfIncomeFileId: proofOfIncome?.id,
        proofOfIncomeReceiptFileId: proofOfIncomeReceipt?.id,
        proofOfAddressFileId: proofOfAddress?.id,
        partnerIdCardFileId: civilPartner?.idCard?.id,
        proofOfWidowerFileId: proofOfWidower?.id,
        proofOfMariageOrDivorceFileId: proofOfMariageOrDivorce?.id,
        proofOfRealStateIds: proofOfRealState?.map(({ id }) => id),
      });
      if (idCard) setIdCard([idCard]);
      if (proofOfIncome) setProofOfIncome([proofOfIncome]);
      if (proofOfIncomeReceipt) setProofOfIncomeReceipt([proofOfIncomeReceipt]);
      if (proofOfAddress) setProofOfAddress([proofOfAddress]);
      if (civilPartner?.idCard) setPartnerIdCard([civilPartner?.idCard]);
      if (proofOfWidower) { setProofOfWidower([proofOfWidower]); }
      if (proofOfMariageOrDivorce) { setProofOfMariageOrDivorce([proofOfMariageOrDivorce]); }
      if (proofOfRealState) { setProofOfRealState(proofOfRealState); }
    }
  }, [selectedBusinessUser]);

  return (
    <div id="documents-form">
      <Form form={form}>
        {((isCreation && loanStatus === 'check-documents')
          || loanStatus !== 'check-documents')
          && (
            <Item
              name="idCardFileId"
              rules={[
                {
                  required: true,
                  message: 'Insira a foto do documento solicitado',
                },
              ]}
            >
              <UploadCard
                id="idCardFileId"
                accept="image/png,image/jpeg,application/pdf"
                label="Identidade, CPF ou CNH"
                actualFile={idCard[0]}
                exampleImages={cardIdExamples}
                alert={(
                  <>
                    O documento deve estar na validade e a foto em boa
                    qualidade. Envie apenas <b>UMA FOTO</b> do documento aberto.
                    Visualize o exemplo para instruções mais detalhadas.
                  </>
                )}
                onCompleted={async ({ id, url, name }) => {
                  await form.setFieldsValue({ idCardFileId: id });
                  setIdCard([{ uid: id, url, name }]);
                }}
              />
            </Item>
          )}
        {((!isCreation
          && (selectedBusinessUser?.user?.proofOfIncome?.analysis
            || selectedBusinessUser?.user?.proofOfIncome?.analysis?.approved === false))
          || (!isCreation && (!selectedBusinessUser?.proofOfIncome))
          || isCreation
          || loanStatus !== 'check-documents')
          && (
            <Item
              name="proofOfIncomeFileId"
              rules={[
                {
                  required: true,
                  message: 'Insira a foto do documento solicitado',
                },
              ]}
            >
              <UploadCard
                id="proofOfIncomeFileId"
                accept="image/png,image/jpeg,application/pdf"
                label="Comprovante de Renda"
                actionButtonText={selectedBusinessUser?.user?.proofOfIncome?.analysis?.approved === false && 'Revisar'}
                incompleteAnimation={
                  selectedBusinessUser?.user?.proofOfIncome?.analysis
                    ?.approved === false
                }
                actualFile={proofOfIncome[0]}
                exampleImages={irpfExamples}
                alert={(
                  <>
                    Declaração completa do Imposto de Renda. Caso não tenha
                    declarado, envie comprovante de{' '}
                    <b>NÃO DECLARANTE DE IMPOSTO DE RENDA</b> (
                    <b>
                      <a
                        href="https://servicos.receita.fazenda.gov.br/Servicos/ConsRest/Atual.app/paginas/index.asp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        CLIQUE AQUI
                      </a>
                    </b>{' '}
                    preencha com os seus dados e após visualizar a declaração
                    salve em PDF para que possa ser enviada).
                  </>
                )}
                onCompleted={async ({ id, url, name }) => {
                  await form.setFieldsValue({ proofOfIncomeFileId: id });
                  setProofOfIncome([{ uid: id, url, name }]);
                }}
              />
            </Item>
          )}
        {(isCreation || loanStatus !== 'check-documents') && (
          <Item
            name="proofOfIncomeReceiptFileId"
            rules={[
              {
                required: true,
                message: 'Insira a foto do documento solicitado',
              },
            ]}
          >
            <UploadCard
              id="proofOfIncomeReceiptFileId"
              accept="image/png,image/jpeg,application/pdf"
              label="Recibo do Comprovante de Renda"
              actualFile={proofOfIncomeReceipt[0]}
              alert={(
                <>
                  Recibo da Declaração Imposto de Renda. Caso não tenha
                  declarado, envie recibo de comprovante de{' '}
                  <b>NÃO DECLARANTE DE IMPOSTO DE RENDA</b>.
                </>
              )}
              onCompleted={async ({ id, url, name }) => {
                await form.setFieldsValue({ proofOfIncomeReceiptFileId: id });
                setProofOfIncomeReceipt([{ uid: id, url, name }]);
              }}
            />
          </Item>
        )}

        {(isCreation || loanStatus !== 'check-documents')
          && (
            <Item
              name="proofOfAddressFileId"
              rules={[
                {
                  required: true,
                  message: 'Insira a foto do documento solicitado',
                },
              ]}
            >
              <UploadCard
                id="proofOfAddressFileId"
                accept="image/png,image/jpeg,application/pdf"
                label="Comprovante de Residência"
                actionButtonText={selectedBusinessUser?.user?.address?.analysis?.approved === false && 'Revisar'}
                incompleteAnimation={
                  selectedBusinessUser?.user?.address?.analysis?.approved === false
                }
                actualFile={proofOfAddress[0]}
                alert={(
                  <>
                    Escolha entre: Conta de luz, água, gás, telefone ou
                    internet, com <b>no máximo 90 dias</b>, ou contrato de
                    locação do imóvel. Envie apenas <b>UMA FOTO</b> da conta
                    aberta. Não aceitamos somente a capa.
                  </>
                )}
                onCompleted={async ({ id, url, name }) => {
                  await form.setFieldsValue({ proofOfAddressFileId: id });
                  setProofOfAddress([{ uid: id, url, name }]);
                }}
              />
            </Item>
          )}

        {['casado', 'uniao-estavel'].includes(civilStatus)
          && (isCreation || loanStatus !== 'check-documents')
          && (
            <Item
              name="partnerIdCardFileId"
              rules={[
                {
                  required: true,
                  message: 'Insira a foto do documento solicitado',
                },
              ]}
            >
              <UploadCard
                id="partnerIdCardFileId"
                accept="image/png,image/jpeg,application/pdf"
                label="Identidade ou CNH do cônjuge"
                actualFile={partnerIdCard[0]}
                exampleImages={cardIdExamples}
                alert="O documento deve estar na validade e a foto em boa qualidade. Envie apenas UMA FOTO do documento aberto"
                onCompleted={async ({ id, url, name }) => {
                  await form.setFieldsValue({ partnerIdCardFileId: id });
                  setPartnerIdCard([{ uid: id, url, name }]);
                }}
              />
            </Item>
          )}

        {finalAmount > capAmount && !fromCooperativa && (
        <Item
          name="proofOfRealStateIds"
        >
          <UploadCard
            id="proofOfRealStateIds"
            accept="image/png,image/jpeg,application/pdf"
            label="Matrículas dos imóveis do Cartório Registro de Imóveis"
            actualFile={proofOfRealState}
            multiples
            onCompleted={async ({ id }) => {
              const ids = form.getFieldValue('proofOfRealStateIds') || [];
              ids.push(id);

              await form.setFieldsValue({ proofOfRealStateIds: ids });
            }}
            onDelete={async ({ id }) => {
              const ids = form.getFieldValue('proofOfRealStateIds') || [];
              const newArrayOfIds = [];
              ids.map((index) => index !== id && newArrayOfIds.push(index));

              await form.setFieldsValue({ proofOfRealStateIds: newArrayOfIds });
            }}
            alert={(
              <>
                {
                      // eslint-disable-next-line max-len
                      `Nas operações acima de ${capAmount / 1000} mill reais, será necessário a inclusão matrículas de imóveis distintas de pelo menos um avalista para qualificação da empresa`
                    }
              </>
                )}
          />
        </Item>
        )}

        {['viuvo'].includes(civilStatus)
          && (isCreation || loanStatus !== 'check-documents')
          && (
            <Item
              name="proofOfWidowerFileId"
              rules={[
                {
                  required: true,
                  message: 'Insira a foto do documento solicitado',
                },
              ]}
            >
              <UploadCard
                id="proofOfWidowerFileId"
                accept="image/png,image/jpeg,application/pdf"
                label="Documentação de Estado Civil"
                actualFile={proofOfWidower[0]}
                alert="A foto do documento deve estar em boa qualidade."
                onCompleted={async ({ id, url, name }) => {
                  await form.setFieldsValue({
                    proofOfWidowerFileId: id,
                  });
                  setProofOfWidower([{ uid: id, url, name }]);
                }}
              />
            </Item>
          )}

        {['casado', 'divorciado'].includes(civilStatus)
          && (isCreation || loanStatus !== 'check-documents')
          && (
            <Item
              name="proofOfMariageOrDivorceFileId"
              rules={[
                {
                  required: true,
                  message: 'Insira a foto do documento solicitado',
                },
              ]}
            >
              <UploadCard
                id="proofOfMariageOrDivorceFileId"
                accept="image/png,image/jpeg,application/pdf"
                label={
                  civilStatus === 'casado'
                    ? 'Certidão de casamento'
                    : 'Averbação da separação ou divórcio'
                }
                actualFile={proofOfMariageOrDivorce[0]}
                alert="A foto do documento deve estar em boa qualidade."
                onCompleted={async ({ id, url, name }) => {
                  await form.setFieldsValue({
                    proofOfMariageOrDivorceFileId: id,
                  });
                  setProofOfMariageOrDivorce([{ uid: id, url, name }]);
                }}
              />
            </Item>
          )}

        {['solteiro', 'uniao-estavel'].includes(civilStatus)
          && (
            <>
              <TermCard
                type={civilStatus}
                alert="A seguir, você assinará um termo que comprova a veracidade do estado civil do sócio. Leia atentamente."
                user={selectedBusinessUser?.user}
                business={business}
                address={address}
              />
              <Item
                name="withProof"
                valuePropName="checked"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Você precisa concordar com o termo'),
                      );
                    },
                  }),
                ]}
              >
                <Checkbox>
                  Confirmo que li, aceito e concordo com as informações
                  descritas acima. Reconheço a assinatura deste termo como
                  válida e comprovante do estado civil do sócio adicionado.
                </Checkbox>
              </Item>
            </>
          )}
      </Form>
    </div>
  );
};

BadesulMicrocreditoDocumentsForm.propTypes = {
  form: PropTypes.shape(),
  businessUser: PropTypes.shape(),
  business: PropTypes.shape(),
  address: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
  civilStatus: PropTypes.string,
  lemitValidated: PropTypes.bool,
  isCreation: PropTypes.bool,
  loanStatus: PropTypes.string,
};
export default BadesulMicrocreditoDocumentsForm;
