// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  #badesul-signature-drawer .ant-drawer-body {
    overflow-y: auto;
  }
}
#badesul-signature-drawer .print-button {
  margin-right: 1rem;
}
@media screen and (max-width: 768px) {
  #badesul-signature-drawer .gyra-drawer-content {
    padding-top: 1rem;
  }
  #badesul-signature-drawer .gyra-drawer-title {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/drawers/SignatureDrawer/components/BadesulSignatureDrawer/styles.less"],"names":[],"mappings":"AAEI;EAAA;IACE,gBAAA;EAAJ;AACF;AAJA;EAOI,kBAAA;AAAJ;AAGE;EAAA;IAEI,iBAAA;EADJ;EADA;IAKI,aAAA;EADJ;AACF","sourcesContent":["#badesul-signature-drawer{\n  .ant-drawer-body{\n    @media(max-width: @md-screen) {\n      overflow-y: auto;\n    }\n  }\n  .print-button {\n    margin-right: 1rem;\n  }\n\n  @media screen and (max-width: @md-screen) {\n    .gyra-drawer-content{\n      padding-top: 1rem;\n    }\n    .gyra-drawer-title {\n      display: none;\n    }\n  }\n}\n@xs-screen: 480px;@sm-screen: 576px;@md-screen: 768px;@lg-screen: 992px;@xl-screen: 1200px;@xxl-screen: 1600px;@content-max-width: 1598px;@border-radius: 1.25rem;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
