import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { detect } from 'detect-browser';
import {
  notification,
} from 'antd';
import {
  TutorialModal,
  Alert,
} from 'components';
import {
  Modal,
  Button,
} from 'components/sdk';

// import { Container } from './styles';

const DigitalSignTutorialModal = ({
  visible,
  onClose,
}) => {
  const [allOptionsModal, setAllOptionsModal] = useState(false);
  const [{ name: browser }] = useState(detect());

  return (
    <>
      <TutorialModal
        visible={visible}
        title="Vamos te ajudar com isso!"
        onClose={onClose}
        steps={[
          {
            title: 'Extensão da BRy',
            content: (
              <>
                <Alert message="Certifique-se que você está fazendo este processo pelo computador" />

                Notamos que você não tem a extensão da BRy instalada em seu navegador.
                <br />
                <b>Instale para que possamos realizar a assinatura digital.</b>
                <br />
                <br />
                <Button
                  block
                  onClick={() => {
                    switch (browser) {
                      case 'chrome':
                      case 'opera':
                      case 'chromium-webview':
                      case 'edge-chromium':
                        window.open('https://chrome.google.com/webstore/detail/assinatura-digital-para-n/dhikfimimcjpoaliefjlffaebdeomeni', '_blank');
                        break;
                      case 'firefox':
                        window.open('https://www.bry.com.br/downloads/extension/firefox/assinatura_digital_bry.xpi', '_self');
                        break;
                      case 'safari':
                        window.open('https://apps.apple.com/br/app/bry-assinatura-digital/id1315721873?mt=12', '_blank');
                        break;
                      default:
                        setAllOptionsModal(true);
                    }
                  }}
                  link
                >
                  Instalar extensão
                </Button>
                <div className="other-option-text">
                  Caso não queira, ainda há a opção de
                  {' '}
                  <b>assinar fisicamente</b>
                </div>
              </>
            ),
          },
          {
            title: 'Instale o certificado',
            nextAction: () => window.location.reload(),
            content: (
              <>
                <b>Instale o certificado (caso não esteja instalado)</b>
                <ol>
                  <li>Localize o arquivo do certificado</li>
                  <li>Clique duas vezes no arquivo e instale</li>
                </ol>
              </>
            ),
          },
        ]}
      />

      <Modal
        visible={allOptionsModal}
        footer={false}
        title="Alguma dessas opções irá te ajudar?"
        centered
        closable={false}
      >
        <>
          <Button
            block
            link
            onClick={() => {
              setAllOptionsModal(false);
              window.open('https://chrome.google.com/webstore/detail/assinatura-digital-para-n/dhikfimimcjpoaliefjlffaebdeomeni', '_blank');
            }}
          >
            Google Chrome | Opera | Edge
          </Button>
          <Button
            block
            link
            onClick={() => {
              setAllOptionsModal(false);
              window.open('https://www.bry.com.br/downloads/extension/firefox/assinatura_digital_bry.xpi', '_self');
            }}
          >
            Firefox
          </Button>
          <Button
            block
            link
            onClick={() => {
              setAllOptionsModal(false);
              window.open('https://apps.apple.com/br/app/bry-assinatura-digital/id1315721873?mt=12', '_blank');
            }}
          >
            Safari
          </Button>
          <br />
          <Button
            block
            onClick={() => {
              setAllOptionsModal(false);
              notification.error({
                message: 'A BRy infelizmente não atende ao seu navegador',
                description: 'Realize este procedimento por um computador ou notebook utilizando o navegador Google Chrome, Opera, Microsoft Edge, Mozilla Firefox ou Safari',
              });
            }}
          >
            Nenhuma opção
          </Button>
        </>
      </Modal>
    </>
  );
};

DigitalSignTutorialModal.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default memo(DigitalSignTutorialModal);
